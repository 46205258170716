// import { maxHeight } from '@mui/system';
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ImageModal = ({ isOpen, onClose, imageUrl }) => {
  if (!isOpen) return null; // Don't render if the modal is not open

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
        <CloseIcon />
        </button>
        <img src={imageUrl} alt="Employee ID" style={styles.image}/>
      </div>
    </div>
  );
};

const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'flex-end', // Aligns modal to the right
      alignItems: 'center', // Aligns modal vertically in the middle
      zIndex: 1000, // Ensure it appears above other elements
    },
    modal: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      position: 'relative',
      maxWidth: '400px',
      width: '50%',
      height : '80%',
      marginRight: '100px', // Adds some space from the right edge
    },
    closeButton: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      background: 'none',
      border: 'none',
      fontSize: '16px',
      cursor: 'pointer',
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      objectFit: 'center',
    },
  };

export default ImageModal;