import axiosInstance from "./authHandle";
const discountRequestURL = "/discount/";
const exportDiscountRequest = 'discount/export-discount-requests/'
const exportUsers = 'account/export-users/'

export const getDiscountRequest = ({requestSearch, seletedStatus, userId, selectemploye, selectApproval}) => {
  const user = userId
  
  return axiosInstance
    .get(discountRequestURL, {
      params: {
        search: requestSearch || "",
        status: seletedStatus || "",
        user: user?.userId || "",
        employee: selectApproval || "",
        relation_type: selectemploye || "",
        page_size: 10
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};

export const getIndivitualDiscountRequest = (id) => {
  return axiosInstance
    .get(`${discountRequestURL}${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};

// export const getexportrequestsingle = (ids) => {
//   console.log(typeof ids)
//   return axiosInstance
//     .get(`/discount/export-discount-requests/?ids=${ids}`)
//     .then((response) => response.data)
//     .catch((error) => {
//       console.error("Error while fetching lead request:", error);
//       throw error;
//     });
// };

export const getexportrequestsingle = (ids) => {
  console.log(typeof ids);
  return axiosInstance
    .get(`/discount/export-discount-requests/`, {
      params: { ids }, // Pass ids as a parameter
      paramsSerializer: (params) => {
        // Manually serialize the params to ensure correct format
        return `ids=${JSON.stringify(params.ids)}`;
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};
export const getexportrequest = ({ids}) => {
  console.log(ids)
  return axiosInstance
    .get(`/discount/export-discount-requests`,{
      params : ids
    })
   
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};

export const getExportDiscountRequest = () => {
  return axiosInstance
  .get(exportDiscountRequest)
  .then((response) => response.data)
  .catch((error) => {
    console.error("Error while fetching lead request:", error);
    throw error;
  })
}

export const getExportUsers = () => {
  return axiosInstance
  .get(exportUsers)
  .then((response) => response.data)
  .catch((error) => {
    console.error("Error while fetching lead request:", error);
    throw error;
  })
}

export const getexportusersingle = (ids) => {
  console.log(typeof ids);
  return axiosInstance
    .get(`account/export-users/`, {
      params: { ids }, // Pass ids as a parameter
      paramsSerializer: (params) => {
        // Manually serialize the params to ensure correct format
        return `ids=${JSON.stringify(params.ids)}`;
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};