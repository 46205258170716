import axiosInstance from "./authHandle";

const getusersList = "/account/users/";
const changePasswordUrl = "/account/user-reset-password";
const userExport = "/account/export-users/"

export const getUsers = (requestSearch, seletedStatus) => {
  console.log('gagag', seletedStatus)
  return axiosInstance
    .get(getusersList, {
      params: {
        search: requestSearch || "",
        status: seletedStatus === '' ?  '' : seletedStatus === 'Active' ? true : false,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};                     

export const createUsers = (data) => {
  return axiosInstance
    .post(getusersList, data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};

export const updateUsers = (data) => {
  return axiosInstance
    .put(getusersList + data?.id + '/', data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};
export const getUserDetailsByID = (id) => {
  return axiosInstance
    .get(`${getusersList}${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};

//  user export 
export const getUserExport = () => {
  return axiosInstance
    .get(`${userExport}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};

export const changeUsersPassword = (userID, data) => {
  return axiosInstance
    .post(`${changePasswordUrl}/${userID}/`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while fetching lead request:", error);
      throw error;
    });
};
