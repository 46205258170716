// import * as XLSX from "xlsx";
// import * as FileSaver from "file-saver";
// import React from "react";
// import Papa from "papaparse";
import React from 'react';
import axios from 'axios';
import FileSaver from 'file-saver';
import { getExportDiscountRequest, getExportUsers, getexportrequestsingle, getexportusersingle } from '../../axiosHandle/discountRequestService';
import Papa from 'papaparse'; // Import PapaParse

const ExportCSV = ({ csvData, fileName }) => {
  console.log('data',csvData, fileName);
  const handleExportClick = async (csvData, fileName) => {
    //alert('test')
    try {
      if(fileName === 'discount-request'){
          if(csvData.length !== 0){
            // alert('test')
            getexportrequestsingle(csvData)
            .then((data) => {
                const blob = new Blob([data], { type: 'text/csv' });
                FileSaver.saveAs(blob, `${fileName}.csv`);
            })
          }
          else{
            getExportDiscountRequest()
            .then((data) => {
                const blob = new Blob([data], { type: 'text/csv' });
                FileSaver.saveAs(blob, `${fileName}.csv`);
            })
          }
          
        }
        else{
            if(csvData.length !== 0){
                // alert('test')
                getexportusersingle(csvData)
                .then((data) => {
                    const blob = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(blob, `${fileName}.csv`);
                })
              }
              else{
                getExportUsers()
                .then((data) => {
                    const blob = new Blob([data], { type: 'text/csv' });
                    FileSaver.saveAs(blob, `${fileName}.csv`);
                })
              }
        }
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };
  return (
    <>
      <span className="mx-2" onClick={() => handleExportClick(csvData,fileName)} >Export</span>
    </>
  );
};

export default ExportCSV;